import { Footer, FooterImg, FooterImgContainer, FooterLink, FooterTriangle } from "../styled/Footer.style";
import { WhiteText } from "../styled/Text.style";
import BadgeAppleStore from './../../assets/img/badge-apple-store.png';
import BadgeGoogleStore from './../../assets/img/badge-google-play.png';

interface FooterProps {
  hasDownloadButtons?: boolean;
  isRelativeTemplate?: boolean;
}

const FooterCmp = (props: FooterProps) => {
  const { hasDownloadButtons, isRelativeTemplate } = props

  return (
    <Footer isRelativeTemplate={isRelativeTemplate}>
      {hasDownloadButtons && <>
        <WhiteText centered>Segui lo stato di avanzamento<br />del sinistro con l'app Unipol.</WhiteText>
        <FooterImgContainer>
          <a href="https://apps.apple.com/it/app/id1279702796" target="_blank" rel="noreferrer">
            <FooterImg src={BadgeAppleStore}></FooterImg>
          </a>
          <a href="https://play.google.com/store/apps/details?id=com.UnipolSaiApp&pli=1" target="_blank" rel="noreferrer">
            <FooterImg src={BadgeGoogleStore}></FooterImg>
          </a>

        </FooterImgContainer>
      </>}

      <FooterTriangle></FooterTriangle>
      <FooterLink href="/privacy.pdf" target="_blank">Termini e privacy</FooterLink>
    </Footer>
  );
};

export default FooterCmp;
