import { NavigateFunction } from "react-router-dom";
import { CommunicationDataType, ContactInfoItemUi } from "./communication/communicationDataType";

export type RouteType = {
  path: string;
  element: any;
  hasBreadcrumb?: boolean;
  breadcrumbIndex?: number;
  defaultBackPath?: string;
  hasFooter?: boolean;
  hasFooterDownloadButtons?: boolean;
  breadcrumbTitles?: string[],
  backBaseRoute?: string,
  tqPageName?:string
}

export const RealtimeBreadcrumbTitles = ['Raccolta Dati', 'Foto evento', 'Cai']
export const ConfirmAuthBreadcrumbTitles = ['', '']


export interface ApplicationState {
  communicationData: CommunicationDataType;
}

export interface DispatchWithCallbackType<T, K> {
  data?: T;
  events?: K;
  callback?: Function;
  navigate?: NavigateFunction;
  computeBareme?: boolean;
}

export interface ActionType {
  type: string;
  payload?: any;
}

export interface ValueLabelOption<T> {
  value: T;
  label: JSX.Element | string;
  subLabel?: string;
}

export const getValueLabelOptions = <T>(options: ValueLabelOption<T>[], val: T) => {
  return options.find(opt => opt.value === val)
}

export const createValueLabelOption = <T>(
  value: T,
  label: JSX.Element | string,
  subLabel?: string) => ({
    value,
    label,
    subLabel,
  })

export interface LabelArrayOption<T> {
  label: string;
  options: ValueLabelOption<T>[];
  type?: any;
  disabled?: boolean;
}

export interface SelectElementsType {
  all?: boolean;
  id?: number;
  value?: boolean;
}

export interface QueryStringState {
  readonly date: string;
  readonly timeframe: string;
  readonly id: String;
  readonly isUsr: Boolean;
}

export class MetadataType {
  public error: boolean = false;
  public errorCode: any = null;
  public isLoading: boolean = false;

  public resetPagination?: boolean = false;

  constructor(meta?: MetadataType) {
    if (meta) {
      this.error = meta.error;
      this.errorCode = meta.errorCode;
      this.isLoading = meta.isLoading;
      this.resetPagination = meta.resetPagination;
    }
  }
}

export type OnConfirmFunction<T> = (data: T) => void;


export interface ModalContentProps<T> {
  initValue?: any;
  initValues?: any;
  close?: any;
  onConfirmAndCloseHandler?: OnConfirmFunction<Partial<T>>;
  additionalData?: any;
}

export interface ContactModalContentProps<T> extends ModalContentProps<T> {
  contactListPropItems: ContactInfoItemUi[];
  contactLabel?: string;
}

export class AddressType {
  public latitude: string = '';
  public longitude: string = '';
  public luogo: string = '';

  constructor(input?: any) {
    if (input) {
      this.latitude = input.latitude;
      this.longitude = input.longitude;
      this.luogo = input.luogo;
    }
  }
}

export enum BoolStringEnum {
  Y = 'Y',
  N = 'N',
  DN = 'DN'
}

export const booleanRadioOptions: ValueLabelOption<BoolStringEnum>[] = [{
  value: BoolStringEnum.Y,
  label: 'Sì'
}, {
  value: BoolStringEnum.N,
  label: 'No'
}]

export interface StateAction<T> {
  type: string;
  payload: T;
}

export const booleanWithDnRadioOptions: ValueLabelOption<BoolStringEnum>[] = [
  ...booleanRadioOptions
  , {
    value: BoolStringEnum.DN,
    label: 'Non so'
  }]
export interface InputItemType {
  propName: string;
  label?: string;
  isRequired?: boolean;
  elClassName?: string;
}

export interface CommonInfoCmpProps<T> {
  questionLabel?: string;
  initValues: T;
  onChange?: (updValues: Partial<T>) => void
}

export interface OtherContactCaiInfoCmpProps<T> extends CommonInfoCmpProps<T> {
  contactListPropItems: ContactInfoItemUi[];
  contactLabel?: string;
}

export const messageRequired = "Questo campo è obbligatorio";
export const emailFormatMessage = "Sicuro? L’indirizzo email inserito non è corretto.";
export const numberFormat = 'Il numero inserito non è corretto. Inserisci solo caratteri numerici (es: 1; 5; 7..)';
export const numberFormatLength = 'Il numero inserito è troppo corto.';

export enum TemplateTypeEnum {
  SIN_INAT = 'SIN_INAT',
  CONFIRM_AND_AUTHORIZE_USER = 'CONFIRM_AND_AUTHORIZE_USER',
  REALTIME_ACCETTAZIONE_CAI = 'REALTIME_ACCETTAZIONE_CAI'
}
export const UNIPOLSAI_COMPLETE = "UNIPOL"
export const UNIPOLSAI_SUBSTRING = 'UNIPOL'