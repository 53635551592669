
import { useNavigate, useParams } from "react-router-dom";
import { Section } from "../components/styled/Containers.style";
import { selectCommunicationData } from "../store";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { PageTitle, TextB, TextDefault, TextDefaultSmall } from "../components/styled/Text.style";
import { Vspace } from '../components/styled/Separators.style'
import { composeUrl, isIpernaturale, isPlateAlphanumericOnly, isPlateLengthCorrect, tqPageView  } from "../utils/utils";
import { useEffect, useMemo, useState } from "react";
import { PATHS } from "../routes/CaiExperienceRouter";
import { loadAniaAct, saveCommunicationAct } from "../store/communication-data/actions";
import { CommunicationType } from "../types/communication/communicationDataType";
import PageContainerCmp from "./PageContainerCmp";
import InputCmp from "../components/input/InputCmp";
import SelectCmp from "../components/input/SelectCmp";
import { UnknownPlateModal } from "../components/unknown-plate-modal/UnknownPlateModal";
import { RealtimeModal } from "../components/modal/RealtimeModal";
import { NextButton, SecondChoiseButton } from "../components/styled/Button.style";

const RaccoltaDatiContropartePage = () => {
    const dispatch = useAppDispatch();
    let navigate = useNavigate();
    let { token } = useParams();

    const communicationData = useAppSelector(selectCommunicationData)
    const { communication, aniaoptions } = communicationData;

    const [original_cp_veic_targa, setOriginalCpVeicTarga] = useState<string>(communication.original_cp_veic_targa)
    const [cp_veic_targa, setCpVeicTarga] = useState<string>(communication.cp_veic_targa)
    const [cp_compagnia_assic, setCpCompagniaAssic] = useState<string>(communication.cp_compagnia_assic || '')
    const [openUnknownPlateModal, setOpenUnknownPlateModal] = useState(false)
    const [formErrors, setFormErrors] = useState<any>()

    /* istanbul ignore next */
    useEffect(() => {
        if (!cp_veic_targa) {  
            if (!isPlateLengthCorrect(cp_veic_targa))
                return setFormErrors({ targa_controparte: { message: 'La targa deve essere lunga almeno 6 cifre.' } })
        
            if (!isPlateAlphanumericOnly(cp_veic_targa))
                return setFormErrors({ targa_controparte: { message: 'La targa deve contenere solo caratteri alfanumerici.' } })
}
        setFormErrors(null)
    }, [cp_veic_targa])

    const isIpernatural = isIpernaturale(communication)

    /* istanbul ignore next */
    const goToNextStepHandler = () => {
        dispatch(saveCommunicationAct({
            data: new CommunicationType({
                ...communication,
                cp_veic_targa,
                cp_compagnia_assic,
                original_cp_veic_targa
            }),
            callback: () => navigate(composeUrl(token, PATHS.ORA_E_LUOGO))
        }))
    }

    useEffect(() => {
        if (!communication.original_cp_veic_targa) {
            setOriginalCpVeicTarga(communication.cp_veic_targa)
        }
        dispatch(loadAniaAct());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const targaValid = useMemo(() => {
        return !!cp_veic_targa && cp_veic_targa.length >= 5 && cp_veic_targa.length <= 8
    }, [cp_veic_targa])

    const cpCompagniaAssicValid = useMemo(() => {
        return !!cp_compagnia_assic
    }, [cp_compagnia_assic])

    /* istanbul ignore next */
    
    const shouldDisableButton = () => {
        const nextStepDisabledIperNatural = !targaValid || !cpCompagniaAssicValid || formErrors;
        const nextStepDisabledOther = !targaValid || formErrors;
        if (isIpernaturale(communication)) {
            return nextStepDisabledIperNatural;
        }
        return nextStepDisabledOther;
    }

    return (
        <>
            <RealtimeModal
                modalOpened={openUnknownPlateModal}
                hTitle="Constatazione amichevole"
                hCloseButton={true}
                /* istanbul ignore next */
                onCloseHandler={() => setOpenUnknownPlateModal(false)}
                onOpenHandler={() => {
                    // Analytics
                    tqPageView('cai:raccolta dati:info mancante controparte')
                }}>
                <UnknownPlateModal />
            </RealtimeModal>

            <PageContainerCmp
                nextStepHandler={goToNextStepHandler}
                routePath={PATHS.RACCOLTA_DATI_CONTROPARTE}
                removeNextBtn
            >
                <Section>
                    <PageTitle>Dati di controparte</PageTitle>
                    <Vspace></Vspace>
                    <TextDefault centered>{/* istanbul ignore next */ isIpernatural ? 'Ci è stato possibile ottenere questi dati perché la controparte è un assicurato Unipol e come te ha la scatola nera installata.' : 'Inserisci la targa della controparte e la sua compagnia, se ne sei a conoscenza.'}</TextDefault>
                    <Vspace></Vspace>
                    <TextDefaultSmall>I campi contrassegnati da * sono obbligatori.</TextDefaultSmall>
                    <Vspace></Vspace>
                    {/* istanbul ignore next */ isIpernatural && <TextB>Se ci sono errori ti chiediamo di correggerli prima di proseguire.</TextB>}
                </Section>

                <Section>
                    <InputCmp
                        value={cp_veic_targa}
                        setValue={(val: string) => setCpVeicTarga(val.toUpperCase())}
                        label={"Targa controparte (*)"}
                        name={"targa_controparte"}
                        labelClassName="marked"
                        errors={formErrors}
                        className={"big-and-spaced"} />
                </Section>

                <Section>
                    <SelectCmp
                        defaultValue={aniaoptions.filter(el => el.label.indexOf(cp_compagnia_assic) >= 0)[0]}
                        options={aniaoptions}
                        setValue={(val: any) => setCpCompagniaAssic(val.label)}
                        name={"compagnia_assicurativa_controparte"}
                        label={"Compagnia assicurativa controparte"}
                        placeholder={"Seleziona la compagnia"}
                    />
                </Section>
                <Vspace qty={2}></Vspace>
                <Section>
                    <NextButton disabled={shouldDisableButton()} onClick={goToNextStepHandler}>Avanti</NextButton>
                    <Vspace></Vspace>
                    {formErrors && original_cp_veic_targa && <SecondChoiseButton onClick={/* istanbul ignore next */ () => setOpenUnknownPlateModal(true)}>Non conosco i dati mancanti</SecondChoiseButton>}
                    {(formErrors || cp_veic_targa === undefined) && !original_cp_veic_targa && <SecondChoiseButton onClick={/* istanbul ignore next */ () => setOpenUnknownPlateModal(true)}>Non conosco la targa della controparte</SecondChoiseButton>}
                </Section>
            </PageContainerCmp>
        </>

    )
}

export default RaccoltaDatiContropartePage