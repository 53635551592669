import { selectCommunicationData } from "../../store"
import { useAppSelector } from "../../store/hooks"
import { CaiCaseEnum, UserRoleEnum } from "../../types/communication/communicationDataType"
import { Header, HeaderInfo } from "../styled/Header.style"
import { AppLogo } from "../styled/Logo.style"
import AppLogoImg from './../../assets/img/Unipol_negativo_rgb_175_88.png'

interface HeaderCmpProps {
    className?: string;
}

const HeaderCmp = (props: HeaderCmpProps) => {
    const { className } = props
    const communicationData = useAppSelector(selectCommunicationData);
    const { communication } = communicationData
    const segments = window.location.pathname.split('/');  //special control for usecase 2 plate validation
    const last = segments[segments.length - 1];

    return (
        <>
            <Header className={className}>
                <AppLogo src={AppLogoImg} alt="Realtime unipol logo" />
                {/* istanbul ignore next */ communication?.veic_targa && !(communication?.userRole === UserRoleEnum.USER_1 && communication?.caiCaseEnum === CaiCaseEnum.CASE_2 && last === "authorize-confirm-claim")
                    && <HeaderInfo>
                        <p>Supporto immediato targa:</p>
                        <p className="info-value">{communication?.veic_targa}</p>
                    </HeaderInfo>
                }
            </Header>
        </>

    )
}

export default HeaderCmp